import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	content: {
		[theme.breakpoints.down('md')]: {
			paddingTop: '2rem',
		},
	},
	firstLine: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem',
		},
	},
	secondLine: {
		fontSize: '2.5rem',
		fontWeight: 700,
		color: '#2a7abc',
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem',
		},
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
	},
}));

export const PricingIntro = ({ intro }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			direction='row'
			justifyContent='center'
			alignItems='center'
			className={classes.content}
			style={{ textAlign: 'center' }}
			xs={12}
			md={10}>
			<PortableText
				content={intro}
				serializers={{
					h2: ({ children }) => (
						<Typography
							variant='h2'
							color='primary'
							className={classes.firstLine}
							style={{ lineHeight: '1.2' }}>
							{children}
						</Typography>
					),
					strong: ({ children }) => (
						<span className={classes.secondLine}>{children}</span>
					),
					normal: ({ children }) => (
						<Typography variant='body1' className={classes.paragraph}>
							{children}
						</Typography>
					),
				}}
			/>
		</Grid>
	);
};
