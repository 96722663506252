import React, { useContext } from 'react';
import { Link } from 'gatsby';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ExpandedSection } from './ExpandedSection';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0px',
		borderRadius: '20px',
		background:'transparent',
	},
	title: {
		fontSize: '1.875rem',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.625rem',
			textAlign: 'left',
		},
	},
	subheader: {
		fontSize: '1.4rem',
		fontWeight: 400,
		color: 'white',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.4rem',
			textAlign: 'left',
		},
	},
	sectionHeader: {
		fontWeight: 700,
		color: theme.workwaveBlue,
	},
	sectionSubHeader: {
		color: theme.workwaveBlue,
	},
}));

export const SoftwareFeatures = ({
	softwareFeatures,
	header,
	subheader,
	modal,
	accentColor,
	pricing,
}) => {
	const classes = useStyles();
	const {
		features,
		expandText,
		expandedListHeader,
		expandedListSubheader,
		associatedProducts,
	} = softwareFeatures;

	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);
	return (
		<>
			{!modal && (
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignContent='center'
					spacing={2}
					style={{
						textAlign: 'center',
						paddingTop: modal || pricing ? '0rem' : '0rem',
						marginBottom: '0rem',
					}}>
					<Grid item>
						<Typography variant='h2' className={classes.sectionHeader}>
							{header}
						</Typography>
					</Grid>

					<Grid item xs={12} md={8}>
						<Typography body='body1' className={classes.sectionSubHeader}>
							{subheader}
						</Typography>
					</Grid>
				</Grid>
			)}
			<Card className={classes.root} style={{ marginTop: modal && '0rem' }}>
				<CardHeader
					title={
						pricing
							? 'A range of modules to provide the tailored experience you need.'
							: expandedListHeader
					}
					subheader={expandedListSubheader}
					classes={{ title: classes.title, subheader: classes.subheader }}
					style={{
						background: pricing ? '#055291' : '#002D5C',
						padding: '20px',
						width: '100%',
						textAlign: 'center',
						color: 'white',
					}}
				/>
				<CardContent
					style={{
						padding: '35px',
						border: '2px solid #C3CAD9',
						borderBottomLeftRadius: '20px',
						borderBottomRightRadius: '20px',
						borderTop: 'none',
					}}>
					<ExpandedSection
						features={features}
						associatedProducts={associatedProducts}
					/>
					<Grid
						style={{ marginBottom: '' }}
						container
						direction='row'
						justifyContent='center'
						alignItems='center'>
						{pricing ? (
							<Button
								variant='contained'
								size='large'
								onClick={(e) => setFormModalOpen(e)}
								style={{
									background: '#055291',

									color: '#FFFFFF',
									marginTop: '2rem',
								}}>
								Get Started
							</Button>
						) : (
							<Link to='/pricing' style={{ textDecorLinktion: 'none' }}>
								<Button
									variant='contained'
									size='large'
									style={{
										background: accentColor ?? '#002D5C',
										color: '#FFFFFF',
										marginTop: '2rem',
									}}>
									{expandText}
								</Button>
							</Link>
						)}
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};
