import React from 'react';

import { Grid } from '@material-ui/core';

import { Container, makeStyles } from '@material-ui/core';
import { SoftwareFeatures } from '../Industry/SoftwareFeatures';
import { PricingIntro } from './PricingIntro';
import { CtaCard } from '../General/CtaCard';
import { PricingCard } from './PricingCard';

const useStyles = makeStyles((theme) => ({
	cards: {
		padding: '6rem 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '2rem 0',
		},
	},
}));

export const PricingBody = ({
	softwareFeatures,
	intro,
	pricingCta,
	pricingCards,
}) => {
	const { ctaHeader, ctaSubheader, ctaText, ctaBackground, ctaLink } =
		pricingCta;
	const classes = useStyles();
	return (
		<>
			<Container
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				<PricingIntro intro={intro} />
				<SoftwareFeatures softwareFeatures={softwareFeatures} pricing />
			</Container>
			<Container>
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
				/>
			</Container>
		</>
	);
};
