import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	bgImg: {
		padding: '1.5rem 0',
		[theme.breakpoints.down('lg')]: {
			padding: '2rem 0',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1.5rem .625rem',
		},
	},
	button: {
		background: 'white',
		color: '#002D5C',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '2rem 0 1rem',
		},
	},
	content: {
		color: '#FFF',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '0 25px',
		},
	},
	calloutHeader: {
		fontSize: '2.5rem',
		lineHeight: 1.2,
		color: '#FFF',
		marginBottom: '1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '15px 0 0 15px',
		},
	},
	calloutSubHeader: {
		color: '#FFF',
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
			padding: '0 15px',
		},
	},
}));

export const CtaCard = ({
	background,
	header,
	subheader,
	ctaText,
	ctaLink,
	internalLink,
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const md = useMediaQuery('(max-width: 960px)');
	return (
		<div style={{ padding: '4rem 0' }}>
			<BgImage
				style={{
					borderRadius: '20px',
					overflow: 'hidden',
					// backgroundSize: 'cover',
				}}
				className={classes.bgImg}
				image={background.asset?.gatsbyImageData}>
				<Grid
					container
					direction='row'
					justifyContent='space-evenly'
					alignItems='center'
					style={{ height: '100%' }}>
					<Grid
						item
						container
						direction='column'
						xs={12}
						md={10}
						lg={7}
						justifyContent='space-evenly'
						alignItems='flex-start'
						style={{ height: '100%' }}>
						<Typography variant='h2' className={classes.calloutHeader}>
							{header}
						</Typography>
						<Typography variant='body1' className={classes.calloutSubHeader}>
							{subheader}
						</Typography>
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						xs={12}
						lg={3}
						style={{ height: '100%' }}>
						{ctaLink ? (
							internalLink ? (
								<Link to={`/${ctaLink}`} style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</Link>
							) : (
								<a
									href={ctaLink}
									target='_blank' aria-label="Opens in a new tab"
									style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</a>
							)
						) : (
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								onClick={handleModalClick}>
								{ctaText}
							</Button>
						)}
					</Grid>
				</Grid>
			</BgImage>
		</div>
	);
};
